'use strict';

var base = require('base/login/login');
var SHA256 = require('crypto-js/sha256');

// eslint-disable-next-line require-jsdoc
function getCookiebyName(name) {
    return document.cookie.split(';').find(cookiename => {
        var cookieId = cookiename.replace(/ /g, '');
        if (cookieId.split('=')[0] === name) {
            return cookieId;
        }
    });
}

base.webToLeadForm = function () {
    $(document).ready(function () {
        var GAClientId = getCookiebyName('_ga');
        if (GAClientId && GAClientId.indexOf('=') > -1) {
            GAClientId = GAClientId.split('=')[1];
        }

        if (GAClientId) {
            $('#regis .GAClientId input').val(GAClientId);
        }

        var GATrackID = getCookiebyName('_gid');
        if (GATrackID && GATrackID.indexOf('=') > -1) {
            GATrackID = GATrackID.split('=')[1];
        }

        if (GATrackID) {
            $('#regis .GATrackID input').val(GAClientId);
        }
        var email = $('#regis input[type="email"]#email').val();
        if (email) {
            $('#regis .GAuserID input').val(SHA256(email).toString());
        }
    });

    $(document.body).on('keyup change', '#regis input[type="email"]#email', function () {
        $('#regis .GAuserID input').val('');
        var email = $(this).val();
        var repeatemail = $('#regis input[type="email"]#repeat-email').val();
        $('#regis .GAuserID input').val(SHA256(email).toString());
        var missmatchmessage = $('#regis input[type="email"]#repeat-email').attr('data-miss-match');
        $('#regis input[type="email"]#repeat-email').parent().find('.invalid-feedback').text('');
        if (repeatemail === email) {
            $('#regis input[type="email"]#repeat-email').removeClass('is-invalid');
        } else {
            $('#regis input[type="email"]#repeat-email').addClass('is-invalid');
            $('#regis input[type="email"]#repeat-email').parent().find('.invalid-feedback').text(missmatchmessage);
        }
    });
    var sfccNpiDuplicateCheck = true;
    var sfccEmailDuplicateCheck = true;
    var sfccDuplicateCheck = true;
    var sfscDuplicateCheck1 = true;
    var sfscDuplicateCheck2 = true;
    var sfscContactUs = 'https://www.galderma.com/us/contact-us';
    var sfccTechEmail = '#';
    var oid;
    var tokenCheck = true;
    var url = $('body').find('.avoid').attr('href');
    var NPIinput;
    var emailInput;

    $(document.body).on('blur', '#regis input[type="text"].NPIid, #regis input[type="email"]#repeat-email, #regis input[type="email"]#email', function () {
        NPIinput = $('body').find('input[type="text"].NPIid').val();
        emailInput = $('body').find('input[type="email"]#email').val();

        var form = {
            email: emailInput,
            npi: NPIinput
        };
        $.ajax({
            url: url,
            method: 'POST',
            data: form,
            success: function (data) {
                sfccNpiDuplicateCheck = data.sfccNpiCheck;
                sfccEmailDuplicateCheck = data.sfccEmailCheck;
                sfccDuplicateCheck = data.sfccCheck;
                sfscDuplicateCheck1 = data.sfscCheck1;
                sfscDuplicateCheck2 = data.sfscCheck2;
                tokenCheck = data.tokenCheck;
                oid = data.oid;
                if (sfccDuplicateCheck === false) {
                    return false;
                } if (sfscDuplicateCheck1 === false) {
                    return false;
                } if (sfscDuplicateCheck2 === false) {
                    return false;
                } if (tokenCheck === false) {
                    return false;
                }
            },
            error: function (xhr, status, error) {
                // console.log(error);
            }

        });
    });

    $(document.body).on('keyup change', '#regis input[type="email"]#repeat-email', function () {
        var repeatemail = $(this).val();
        var email = $('#regis input[type="email"]#email').val();
        var missmatchmessage = $(this).attr('data-miss-match');
        $(this).parent().find('.invalid-feedback').text('');
        if (repeatemail === email) {
            $(this).removeClass('is-invalid');
        } else {
            $(this).addClass('is-invalid');
            $(this).parent().find('.invalid-feedback').text(missmatchmessage);
        }
    });

    $(document.body).on('paste', '#regis input[type="email"]#repeat-email', function (e) {
        e.preventDefault();
        return false;
    });

    $('.submit-form').on('click', function () {
        if ($('body').find('.focus-speaciality option:selected').val() == '') {
            $('.dropdown-error-msg').text('Please select an option');
        } else {
            $('.dropdown-error-msg').text('');
        }
    });

    $('.submit-form').on('click', function () {
        if ($('body').find('.where_did option:selected').val() == '') {
            $('.dropdown-error-msg1').text('Please select an option');
        } else {
            $('.dropdown-error-msg1').text('');
        }
    });
    $('.submit-form').on('click', function () {
        if ($('body').find('.business_location option:selected').val() == '') {
            $('.dropdown-error-msg2').text('Please select an option');
        } else {
            $('.dropdown-error-msg2').text('');
        }
    });

    $('#regis form').submit(function (event) {
        $('body').find('.submit-form').prop("disabled", true);
        var resetPassword = $('body').find('.resetPasswordUrl').attr('href');
        var isVermontLicence = false;
        var $vermontLicence = $('#regis input[type="radio"]:checked');
        isVermontLicence = $vermontLicence.val() === '1';
        if (isVermontLicence) {
            $vermontLicence.addClass('is-invalid');
            $('#' + $vermontLicence.attr('aria-describedby')).text($vermontLicence.data('existinglicence'));
            $('#' + $vermontLicence.attr('aria-describedby')).addClass('d-block');
        } else {
            $('#' + $vermontLicence.attr('aria-describedby')).text($vermontLicence.data('existinglicence'));
            $('#' + $vermontLicence.attr('aria-describedby')).removeClass('d-block');
        }

        var repeatemail = $(this).find('input[type="email"]#repeat-email').val();
        var email = $(this).find('input[type="email"]#email').val();
        $(this).find('input[type="email"]#repeat-email').parent().find('.invalid-feedback')
            .text('');
        var missmatchmessage = $(this).find('input[type="email"]#repeat-email').attr('data-miss-match');
        if (repeatemail !== email) {
            $(this).find('input[type="email"]#repeat-email').addClass('is-invalid');
            $(this).find('input[type="email"]#repeat-email').parent().find('.invalid-feedback')
                .text(missmatchmessage);
            return false;
        }
        if (isVermontLicence) {
            return false;
        }
        var sfccErrorMsg = $('.email-error-msg').attr('data-error-msg');
        var sfccNpiErrorMsg = $('.email-error-msg').attr('data-npi-error-msg');
        var sfccEmailErrorMsg = $('.email-error-msg').attr('data-email-error-msg');
        var sfscErrorMsg1 = $('.email-error-msg').attr('data-sfscerror-msg1');
        var sfscErrorMsg2 = $('.email-error-msg').attr('data-sfscerror-msg2');
        var sfccTechText = $('.email-error-msg').attr('data-tech-email-id');
        var tech_support_email = $('.email-error-msg').attr('data-tech-email');
        var sfscTokenError = $('.email-error-msg').attr('data-sfsc-token-error');
        if (!sfccDuplicateCheck) {
            $('.email-error-msg').html(sfccErrorMsg + ' ' + "<a href='" + resetPassword + "'>here.</a><br/>" + sfccTechText + ' ' + "<a href='" + sfccTechEmail + "'>" + tech_support_email + '</a>');
            $('body').find('.submit-form').prop("disabled", false);
            return false;
        } if (!sfccNpiDuplicateCheck) {
            $('.email-error-msg').html(sfccNpiErrorMsg + ' ' + "<a href='" + resetPassword + "'>here.</a><br/>" + sfccTechText + ' ' + "<a href='" + sfccTechEmail + "'>" + tech_support_email + '</a>');
            $('body').find('.submit-form').prop("disabled", false);
            return false;
        } if (!sfccEmailDuplicateCheck) {
            $('.email-error-msg').html(sfccEmailErrorMsg + ' ' + "<a href='" + resetPassword + "'>here.</a><br/>" + sfccTechText + ' ' + "<a href='" + sfccTechEmail + "'>" + tech_support_email + '</a>');
            $('body').find('.submit-form').prop("disabled", false);
            return false;
        } if (!sfscDuplicateCheck1) {
            $('.email-error-msg').html(sfscErrorMsg1);
            $('body').find('.submit-form').prop("disabled", false);
            return false;
        } if (!sfscDuplicateCheck2) {
            $('.email-error-msg').html(sfscErrorMsg2 + ' ' + "<a href='" + sfscContactUs + "'>here.</a>");
            $('body').find('.submit-form').prop("disabled", false);
            return false;
        } if (!tokenCheck) {
            $('.email-error-msg').html(sfscTokenError);
            $('body').find('.submit-form').prop("disabled", false);
            return false;
        }
        $(this).find('input[type="email"]#repeat-email').removeClass('is-invalid');
        var oidValue = oid;
        $('#signUp').val(oidValue);
        var response = grecaptcha.getResponse();
        if (response.length == 0) {
            $('.dropdown-error-msg-recaptcha').text('Please verify');
            return false;
        }
        $('#regis form').submit();

        $('#regis form').submit();
    });
};

module.exports = base;
